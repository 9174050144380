<template>
  <v-container fluid class="pa-0">
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-alert
          v-if="showAddUserAlert"
          type="warning"
          color="#AD4C0B"
          icon="mdi-alert-outline"
          text
          class="caption font-weight-regular py-3 px-5"
          height="44px"
          dismissible
        >
          {{ $t("userLimitExceeded") }}
          <a class="primary--text" @click="$router.push('/subscription')">
            {{ $t("upgradePlan") }}</a
          >
          {{ $t("toAddMoreUser") }}
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="8"
        md="7"
        lg="8"
        class="text-h5 font-weight-medium"
        :class="$vuetify.breakpoint.mdAndUp ? 'pl-8' : 'pl-5'"
      >
        {{ $t("users") }}
      </v-col>
      <v-spacer></v-spacer>
      <div class="mr-3">
        <v-col
          v-if="$vuetify.breakpoint.mdAndUp && checkPermission('addUser')"
          class="ml-8"
        >
          <v-btn
            color="primary"
            class="text-none rounded-lg"
            @click="addUser"
            :disabled="showAddUserAlert"
            ><v-icon>mdi-plus</v-icon> {{ $t("addUser") }}</v-btn
          >
        </v-col>
      </div>
    </v-row>
    <v-row class="justify-end">
      <v-col cols="auto" v-if="showSuccessMsg">
        <v-alert
          type="success"
          dismissible
          max-height="250"
          dense
          text
          class="ma-0"
        >
          <span> User updated successfully ! </span>
        </v-alert>
      </v-col>
    </v-row>

    <v-row class="mb-2">
      <v-divider></v-divider>
    </v-row>
    <v-card
      elevation="0"
      width="100%"
      :class="$vuetify.breakpoint.smAndDown ? 'mb-12' : null"
    >
      <v-card-title class="pr-0">
        <v-row>
          <span
            class="text-subtitle-2 my-auto"
            :class="$vuetify.breakpoint.mdAndUp ? 'ml-4' : 'ml-1'"
            >{{ $t("totalUsers") }} : {{ usersData.length }}</span
          >
          <v-spacer></v-spacer>
          <v-col
            cols="12"
            lg="4"
            md="4"
            xl="4"
            :class="!$vuetify.breakpoint.mdAndUp ? 'pl-0 pr-4' : 'pr-6'"
          >
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              :label="$t('search')"
              single-line
              hide-details
              flat
              solo
              background-color="grey lighten-4"
              class="rounded-lg"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-title>

      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="usersData"
        :items-per-page="!$vuetify.breakpoint.mdAndUp ? -1 : 15"
        :search="search"
        item-key="email"
        color="primary"
        class="elevation-0 pa-0"
        :class="$vuetify.breakpoint.mdAndUp ? 'ml-2' : null"
        checkbox-color="primary"
        :hide-default-footer="!$vuetify.breakpoint.mdAndUp"
        :loading="usersData.length == 0"
        loading-text="Loading... Please wait"
        mobile-breakpoint="950"
        disable-sort
      >
        <template v-slot:[`top`]>
          <v-dialog
            v-model="dialog"
            persistent
            :fullscreen="$vuetify.breakpoint.smAndDown"
            max-width="500px"
          >
            <v-card v-if="!isWelcomeEmailSent">
              <v-card-title>
                <span class="text-h6 font-weight-medium">{{ formTitle }}</span>
                <v-spacer></v-spacer>
                <v-btn icon aria-label="close" @click="dialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-container class="px-0">
                  <v-row no-gutters justify="center">
                    <v-col cols="12">
                      <v-alert
                        v-show="isUserExists"
                        color="red"
                        dismissible
                        text
                        icon="mdi-alert-circle-outline"
                        dense
                        type="error"
                      >
                        <span class="my-auto font-weight-regular caption">{{
                          $t("userInviteError")
                        }}</span>
                      </v-alert>
                      <v-alert
                        v-show="emailInUse"
                        color="red"
                        dismissible
                        text
                        icon="mdi-alert-circle-outline"
                        dense
                        type="error"
                      >
                        <span class="my-auto font-weight-regular caption">{{
                          $t("emailAllreadyRegistered")
                        }}</span>
                      </v-alert>
                    </v-col>
                  </v-row>
                  <v-row no-gutters justify="center">
                    <v-col cols="12">
                      <v-row
                        v-if="
                          editedItem.status === 'Active' ||
                          editedItem.status === 'Inactive'
                        "
                        no-gutters
                      >
                        <v-col
                          :cols="$vuetify.breakpoint.width <= 365 ? 6 : 4"
                          lg="3"
                          md="3"
                          sm="3"
                          class="select-view"
                        >
                          <v-select
                            :disabled="!allowChangeStatus"
                            v-model="editedItem.status"
                            :items="statusOptions"
                            :menu-props="{ bottom: true, offsetY: true }"
                            small-chips
                            outlined
                            dense
                            rounded
                            required
                            label=""
                            :aria-label="editedItem.status"
                          >
                            <template #selection="{ item }">
                              <v-chip
                                small
                                :color="getColor(item)"
                                class="white--text"
                              >
                                {{ item }}</v-chip
                              >
                            </template></v-select
                          >
                        </v-col>
                        <v-tooltip
                          v-if="!allowChangeStatus"
                          :right="!$vuetify.breakpoint.smAndDown"
                          :bottom="$vuetify.breakpoint.smAndDown"
                          max-width="400"
                          class="pa-0"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              color="primary"
                              dark
                              v-bind="attrs"
                              v-on="on"
                              class="mb-6"
                            >
                              mdi-information-outline
                            </v-icon>
                          </template>
                          <span
                            >This user has been deactivated. Please contact our
                            support team at<a> support@norfielddp.com</a></span
                          >
                        </v-tooltip>
                      </v-row>

                      <v-text-field
                        v-model="editedItem.name"
                        :error-messages="nameErrors"
                        :label="$t('fullName')"
                        outlined
                        required
                        dense
                        @input="$v.editedItem.name.$touch()"
                        @blur="$v.editedItem.name.$touch()"
                      ></v-text-field>
                      <v-text-field
                        v-model="editedItem.email"
                        :error-messages="emailErrors"
                        :label="$t('emailAddress')"
                        outlined
                        required
                        dense
                        @input="$v.editedItem.email.$touch()"
                        @blur="$v.editedItem.email.$touch()"
                      ></v-text-field>
                      <v-text-field
                        v-model="editedItem.phone"
                        :error-messages="phoneErrors"
                        :label="$t('phoneNumber')"
                        outlined
                        required
                        dense
                        @input="$v.editedItem.phone.$touch()"
                        @blur="$v.editedItem.phone.$touch()"
                      ></v-text-field>
                      <v-select
                        v-model="editedItem.role"
                        :items="userRoles[companyUserRole]"
                        :error-messages="roleErrors"
                        item-value="value"
                        item-text="text"
                        :menu-props="{ bottom: true, offsetY: true }"
                        :disabled="
                          editedIndex !== -1 && companyUserRole != 'admin'
                        "
                        outlined
                        required
                        dense
                        :label="$t('role')"
                        @change="$v.editedItem.role.$touch()"
                        @blur="$v.editedItem.role.$touch()"
                      >
                        <template v-slot:item="{ item, on, attrs }">
                          <v-list-item v-bind="attrs" v-on="on">
                            <v-list-item-content>
                              <v-list-item-title
                                class="text-left"
                                v-text="item.text"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-select>
                      <v-row
                        v-if="approverCheck"
                        class="px-4"
                        :class="!isApprover ? 'pb-5' : 'pb-2'"
                      >
                        <v-switch
                          v-model="isApprover"
                          dense
                          inset
                          color="info"
                          label="Approval Required"
                          class="info--text switch-track-inset-opacity switch-track-inset-height mt-3 mr-2"
                          background-color="white"
                        ></v-switch
                        ><v-tooltip
                          :right="!$vuetify.breakpoint.smAndDown"
                          :bottom="$vuetify.breakpoint.smAndDown"
                          max-width="400"
                          class="pa-0"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              color="primary"
                              dark
                              v-bind="attrs"
                              v-on="on"
                            >
                              mdi-information-outline
                            </v-icon>
                          </template>
                          <span>{{ $t("theAssignedApproveText") }}</span>
                        </v-tooltip>
                      </v-row>
                      <v-select
                        v-if="isApprover && approverCheck"
                        v-model="editedItem.approver_id"
                        :items="approversList"
                        :error-messages="roleErrors"
                        item-value="user_id"
                        item-text="name"
                        :menu-props="{ bottom: true, offsetY: true }"
                        :disabled="
                          editedIndex !== -1 && companyUserRole != 'admin'
                        "
                        outlined
                        required
                        dense
                        :label="$t('approverName')"
                        @change="$v.editedItem.role.$touch()"
                        @blur="$v.editedItem.role.$touch()"
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions class="mt-4">
                <v-footer
                  absolute
                  color="white"
                  :elevation="$vuetify.breakpoint.smAndDown ? 3 : 1"
                >
                  <v-row
                    no-gutters
                    class="px-2 mb-3"
                    :class="{
                      'mr-4 d-flex justify-end': $vuetify.breakpoint.mdAndUp,
                    }"
                  >
                    <v-col
                      cols="6"
                      lg="2"
                      md="2"
                      class="pr-3"
                      :class="{ 'mx-2': $vuetify.breakpoint.mdAndUp }"
                    >
                      <v-btn
                        block
                        outlined
                        class="text-none rounded-lg"
                        color="primary"
                        @click="close"
                      >
                        {{ $t("cancel") }}
                      </v-btn>
                    </v-col>
                    <v-col
                      cols="6"
                      :lg="editedIndex !== -1 ? 2 : 3"
                      :md="editedIndex !== -1 ? 2 : 3"
                      class="pl-3"
                      :class="{ 'mx-2': $vuetify.breakpoint.mdAndUp }"
                    >
                      <v-btn
                        v-if="editedIndex !== -1"
                        block
                        class="text-none rounded-lg"
                        color="primary"
                        :disabled="$v.$invalid || isApproverCheck"
                        @click="update"
                      >
                        {{ $t("update") }}
                      </v-btn>
                      <v-btn
                        v-if="editedIndex === -1"
                        block
                        class="text-none rounded-lg"
                        color="primary"
                        :disabled="
                          $v.$invalid || isApproverCheck || !enableInvite
                        "
                        @click="submit"
                      >
                        {{ $t("inviteUser") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-footer>
              </v-card-actions>
            </v-card>
            <v-card v-else class="pa-4"
              ><ConfirmationMessage
                :icon="welcomeEmailSentObject.icon"
                :text="welcomeEmailSentObject.text"
                :button="welcomeEmailSentObject.button"
                :show-logo="welcomeEmailSentObject.showLogo"
                :background-color="welcomeEmailSentObject.backgroundColor"
                @onClickDone="onClickDone"
            /></v-card>
          </v-dialog>
        </template>
        <template v-slot:[`item.name`]="{ item }">
          <v-row class="text-subtitle-2 font-weight-bold ma-0">
            {{ item.name }}</v-row
          >
          <v-row class="ma-0"> {{ item.email }}</v-row>
        </template>
        <template v-slot:[`item.persona`]="{ item }">
          {{ getRoles(item.persona) }}
        </template>
        <template v-slot:[`item.approver_id`]="{ item }">
          <span v-if="item.approver_id">{{
            approverRoleUsersMap[item.approver_id]
              ? approverRoleUsersMap[item.approver_id].name
              : ""
          }}</span>
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
          {{ item.created_at }}
        </template>
        <template v-slot:[`item.updated_at`]="{ item }">
          <v-row v-if="item.updated_at === 'Invalid date'" justify="center">
            —
          </v-row>
          <v-row v-else>
            {{ item.updated_at }}
          </v-row>
        </template>
        <template v-slot:[`item.edit`]="{ item }">
          <v-icon
            v-if="checkPermission('editUser')"
            :disabled="companyUserId === item.user_id"
            class="mr-2"
            aria-label="Edit"
            @click="editItem(item)"
          >
            mdi-pencil-outline
          </v-icon>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-chip :color="getColor(item.status)" small class="white--text">
            {{ $t(item.status) }}
          </v-chip>
          <v-btn
            v-if="item.status === 'expired' || item.status === 'pending'"
            color="primary"
            class="text-none rounded-lg white--text ml-2"
            height="20"
            @click="resendInvite(item)"
          >
            {{ $t("resend") }}
          </v-btn>
        </template>
        <template v-if="!$vuetify.breakpoint.mdAndUp" v-slot:body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.email" class="pb-4">
              <v-card height="210" elevation="0">
                <v-row>
                  <v-col cols="7" class="ml-1">
                    <span class="text-subtitle-2 font-weight-bold">
                      {{ item.name }}</span
                    >
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col class="pr-0">
                    <v-chip
                      x-small
                      :color="getColor(item.status)"
                      class="white--text mr-4"
                    >
                      {{ $t(item.status) }}
                    </v-chip>
                    <v-btn
                      v-if="
                        item.status === 'expired' || item.status === 'pending'
                      "
                      color="primary"
                      class="text-none rounded-lg white--text"
                      height="20"
                      @click="resendInvite(item)"
                    >
                      {{ $t("resend") }}
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row class="ml-1">
                  <v-col cols="4" class="pa-0">{{ $t("role") }}:</v-col>
                  <v-col class="pa-0 font-weight-medium">{{
                    getRoles(item.persona)
                  }}</v-col>
                </v-row>
                <v-row class="ml-1"
                  ><v-col cols="4" class="pa-0">{{ $t("emailId") }}:</v-col
                  ><v-col class="pa-0 font-weight-medium">
                    {{ item.email }}</v-col
                  ></v-row
                >
                <v-row v-if="item.approver_id" class="ml-1"
                  ><v-col cols="4" class="pa-0">{{ $t("approver") }}:</v-col
                  ><v-col class="pa-0 font-weight-medium">
                    {{
                      approverRoleUsersMap[item.approver_id]
                        ? approverRoleUsersMap[item.approver_id].name
                        : ""
                    }}</v-col
                  ></v-row
                >
                <v-row class="ml-1"
                  ><v-col cols="4" class="pa-0">{{ $t("created") }}:</v-col
                  ><v-col class="pa-0 font-weight-medium">
                    {{ item.created_at }}</v-col
                  ></v-row
                >
                <v-row class="ml-1"
                  ><v-col cols="4" class="pa-0">{{ $t("updated") }}:</v-col
                  ><v-col class="pa-0 font-weight-medium">
                    <span
                      v-if="item.updated_at === 'Invalid date'"
                      justify="center"
                    >
                      —
                    </span>
                    <span v-else>
                      {{ item.updated_at }}
                    </span></v-col
                  ></v-row
                >
                <v-row
                  class="ml-1 pt-4 mb-4"
                  v-if="checkPermission('editUser')"
                >
                  <v-col cols="3" class="pa-0">
                    <v-icon
                      :disabled="companyUserId === item.user_id"
                      class="mr-3"
                      aria-label="Edit"
                      @click="editItem(item)"
                    >
                      mdi-pencil-outline
                    </v-icon>
                  </v-col>
                </v-row>
              </v-card>
              <v-divider></v-divider>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-card>
    <v-bottom-navigation
      v-if="$vuetify.breakpoint.smAndDown && checkPermission('addUser')"
      v-model="bottomNavbar"
      fixed
      horizontal
      height="70"
      class="px-2 py-4"
      ><v-row class="px-4 py-2">
        <v-btn
          color="primary"
          class="text-none rounded-lg text-h6 white--text"
          block
          height="50"
          @click="addUser"
          >{{ $t("addUser") }}<v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-row></v-bottom-navigation
    >
    <v-dialog
      v-model="resendDialog"
      persistent
      :fullscreen="$vuetify.breakpoint.smAndDown"
      max-width="500px"
      ><v-card>
        <ConfirmationMessage
          :icon="welcomeEmailSentObject.icon"
          :text="welcomeEmailSentObject.text"
          :button="welcomeEmailSentObject.button"
          :show-logo="welcomeEmailSentObject.showLogo"
          :background-color="welcomeEmailSentObject.backgroundColor"
          @onClickDone="onClickDone"
        />
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from "moment";
import { validationMixin } from "vuelidate";
import { helpers } from "vuelidate/lib/validators";
import ConfirmationMessage from "../../molecules/ConfirmationMessage.vue";
import ERRORS from "@/static/errors.json";
import UserDataMixin from "@/mixins/UserData";
import {
  required,
  maxLength,
  minLength,
  email,
} from "vuelidate/lib/validators";
const phone = helpers.regex(
  "serial",
  /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
);
export default {
  name: "Users",
  mixins: [validationMixin, UserDataMixin],
  props: {},
  validations: {
    editedItem: {
      name: {
        required,
        maxLength: maxLength(80),
        minLength: minLength(1),
      },
      phone: { required, phone },
      email: { required, email },
      role: { required },
    },
  },
  data() {
    return {
      isUserExists: false,
      showSuccessMsg: false,
      enableInvite: true,
      emailInUse: false,
      isWelcomeEmailSent: false,
      allowChangeStatus: true,
      welcomeEmailSentObject: {
        text: {
          bodyLabel: "We sent an email to <strong>test@gmail.com</strong> with a link to set up account.",
          headerLabel: "Email Sent!",
          bodyLabelParams: {
            email: "test@gmail.com",
          },
        },

        button: {
          buttonLabel: "Done",
          buttonColor: "#0073D1",
          buttonOutlined: false,
          buttonEvent: "onClickDone",
        },
        icon: {
          iconName: "",
          iconColor: "",
          isIcon: false,
        },
        showLogo: false,
        backgroundColor: false,
      },
      userRoles: {
        sysop: [{ text: "System Admin", value: "sysop" }],
        admin: [],
      },
      bottomNavbar: true,
      search: "",
      singleSelect: false,
      dialog: false,
      resendDialog: false,
      editedIndex: -1,
      editedItem: {
        name: "",
        email: "",
        phone: "",
        role: [],
        status: "",
      },
      originalItem: { name: "", email: "", phone: "", role: [], status: "" },
      statusOptions: ["Active", "Inactive"],
      selected: [],
      isApprover: "",
      usersData: [],
      approverRoleUsers: [],
      defaultRoles: ["sysop", "admin", "user"],
      approverRoleUsersMap: {},
    };
  },
  computed: {
    isApproverCheck() {
      if (this.isApprover)
        if (this.editedItem.approver_id) return false;
        else return true;
      else return false;
    },
    showAddUserAlert() {
      if (this.$store.state.User.user.role[0] === "sysop") return false;
      const totalActiveUsers = this.usersData.filter((item) => {
        return item.status !== "inactive";
      });
      if (this.$store.state.Company.company.users <= totalActiveUsers.length)
        return true;
      else return false;
    },
    approverCheck() {
      const plan =
        this.$store.state.Subscription.subscriptionMap[
          this.$store.state.Company.company.subscription_id
        ]?.name;
      return (
        !(
          this.editedItem.role === "admin" ||
          this.editedItem.role === "approver" ||
          !this.editedItem.role
        ) &&
        plan !== "Free" &&
        this.approverRoleUsers.length !== 0
      );
    },
    headers() {
      if (this.companyUserRole == "admin") {
        return [
          {
            text: "User",
            value: "name",
          },
          { text: 'Email', value: 'email', align: ' d-none' },
          { text: "Role", value: "persona" },
          { text: "Approver", value: "approver_id" },
          { text: "Created On", value: "created_at" },
          { text: "Updated On", value: "updated_at" },
          { text: "Status", value: "status" },
          { text: "Edit", value: "edit" },
        ];
      } else {
        return [
          {
            text: "User",
            value: "name",
          },
          { text: 'Email', value: 'email', align: ' d-none' },
          { text: "Role", value: "persona" },
          { text: "Created On", value: "created_at" },
          { text: "Updated On", value: "updated_at" },
          { text: "Status", value: "status" },
          { text: "Edit", value: "edit" },
        ];
      }
    },
    companyId() {
      return this.$store.state.Company.company.company_id;
    },
    approversList() {
      return this.approverRoleUsers.filter((element) => {
        return element.user_id !== this.editedItem.user_id;
      });
    },
    companyUserRole() {
      return this.$store.state.User.user.role[0];
    },
    companyUserId() {
      return this.$store.state.User.user.user_id;
    },
    formTitle() {
      return this.editedIndex === -1 ? this.$t("addUser") : this.$t("editUser");
    },
    roleErrors() {
      const errors = [];
      if (!this.$v.editedItem.role.$dirty) return errors;
      !this.$v.editedItem.role.required &&
        errors.push(this.$t("roleRequiredError"));
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.editedItem.name.$dirty) return errors;
      !this.$v.editedItem.name.maxLength &&
        errors.push(this.$t("nameLengthError"));
      !this.$v.editedItem.name.required &&
        errors.push(this.$t("nameRequiredError"));
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.editedItem.email.$dirty) return errors;
      !this.$v.editedItem.email.email &&
        errors.push(this.$t("emailValidError"));
      !this.$v.editedItem.email.required &&
        errors.push(this.$t("emailRequiredError"));
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.editedItem.phone.$dirty) return errors;
      !this.$v.editedItem.phone.phone &&
        errors.push(this.$t("phoneValidError"));
      !this.$v.editedItem.phone.required &&
        errors.push(this.$t("phoneRequiredError"));
      return errors;
    },
    approverRoles() {
      const rolesAndPermissions =
        this.$store.state.Company.company.options.rolesAndPermissions;
      return Object.keys(rolesAndPermissions).filter((key) => {
        return rolesAndPermissions[key].includes("approveTickets");
      });
    },
  },
  watch: {
    companyId() {
      this.getUsers(this.companyId);
      this.companyRoles();
    },
    dialog(val) {
      val || this.close();
    },
    showSuccessMsg() {
      if (this.showSuccessMsg) {
        setTimeout(() => {
          this.showSuccessMsg = false;
        }, 5000);
      }
    },
  },
  created() {
    this.moment = moment;
    this.getUsers(this.companyId);
    this.companyRoles();
  },
  methods: {
    getRoles(role) {
      switch (role) {
        case "admin":
          return this.$t("admin");
        case "user":
          return this.$t("user");
        case "approver":
          return this.$t("approver");
        case "sysop":
          return this.$t("sysop");
        default:
          return role;
      }
    },
    companyRoles() {
      if (this.companyUserRole != "sysop") {
        let roles =
          this.$store.state.Company.company.options?.rolesAndPermissions;
        for (let key in roles) {
          this.userRoles.admin.push({ text: this.getRoles(key), value: key });
        }
      }
    },
    async getUsers(company_id) {
      this.usersData = this.$store.state.User.companyUsers;
      const userData = await this.$store.dispatch("getCompanyUsers", {
        company_id: company_id,
      });
      const company = this.$store.state.Company.company;
      if (userData.status !== "error") {
        userData.sort(function (a, b) {
          return new Date(b.created_at) - new Date(a.created_at);
        });
        userData.forEach((item) => {
          item.created_at = this.moment(item.created_at).format(
            "MM/DD/YYYY hh:mm A",
          );
          item.updated_at = this.moment(item.updated_at).format(
            "MM/DD/YYYY hh:mm A",
          );
          if (
            this.approverRoles.includes(item.persona) &&
            item.status === "active"
          )
            this.approverRoleUsers.push(item);
          this.approverRoleUsersMap[item.user_id] = item;
        });
        this.$store.commit("setCompanyUsers", userData);
        this.usersData = userData;
      }
    },
    getColor(status) {
      if (status.toLowerCase() === "pending") return "#C35309";
      else if (status.toLowerCase() === "inactive") return "#616161";
      else if (status.toLowerCase() === "expired") return "#FA1405";
      else return "#1E853A";
    },
    editItem(item) {
      if (item.disabled_by_sysop === true) {
        this.allowChangeStatus = false;
      }
      this.isUserExists = false;
      this.emailInUse = false;
      this.isWelcomeEmailSent = false;
      this.isApprover = false;
      this.editedIndex = this.usersData.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.originalItem = Object.assign({}, item);
      this.editedItem.role = this.editedItem.persona;
      this.editedItem.status = this.$t(this.editedItem.status);
      this.isApprover = this.editedItem.approver_id ? true : false;
      this.dialog = true;
    },
    addUser() {
      this.isUserExists = false;
      this.emailInUse = false;
      this.editedIndex = -1;
      this.editedItem = {};
      this.dialog = true;
      this.enableInvite = true;
      this.isApprover = false;
      this.$v.$reset();
    },
    onClickDone() {
      this.isWelcomeEmailSent = false;
      this.dialog = false;
      this.resendDialog = false;
    },
    async update() {
      let updateUserStatus = {};
      this.showSuccessMsg = false;
      let userObject = {
        id: this.editedItem.user_id,
        company_id: this.companyId,
        name: this.editedItem.name,
        email: this.editedItem.email,
        phone: this.editedItem.phone,
        status: this.editedItem.status.toLowerCase(),
        role: this.defaultRoles.includes(this.editedItem.role)
          ? this.editedItem.role
          : "user",
        persona: this.editedItem.role,
      };
      if (this.editedItem.approver_id && this.isApprover)
        userObject.approver_id = this.editedItem.approver_id;
      else userObject.approver_id = null;

      // Update pending user email
      if (
        this.originalItem.email !== this.editedItem.email &&
        userObject.status !== "active"
      ) {
        userObject.status = "pending";
        updateUserStatus = await this.$store.dispatch(
          "reInviteUser",
          userObject,
        );
      } else {
        // Update active user
        updateUserStatus = await this.$store.dispatch("updateUser", userObject);
      }

      if (updateUserStatus.status !== "error") {
        this.onClickDone();
        this.getUsers(this.companyId);
        this.showSuccessMsg = true;
        if (
          this.originalItem.email !== this.editedItem.email &&
          userObject.status !== "active"
        ) {
        this.welcomeEmailSentObject.text.bodyLabel = `We sent an email to <strong>${this.editedItem.email}</strong> with a link to set up account.`;
          this.welcomeEmailSentObject.text.bodyLabelParams.email =
            this.editedItem.email;
          this.isWelcomeEmailSent = true;
        } else {
          this.userUpdated = true;
          this.isWelcomeEmailSent = false;
        }
      }
      // error when updating email to one that already exists
      else if (updateUserStatus.error === ERRORS.USER_EMAIL_ALREADY_EXIST) {
        // set the email to original email
        this.editedItem.email = this.originalItem.email;
        this.emailInUse = true;
      } else {
        this.$emit("error");
      }
    },
    async submit() {
      this.enableInvite = false;
      this.isUserExists = false;

      this.emailInUse = false;

      let userObject = {
        name: this.editedItem.name,
        email: this.editedItem.email,
        phone: this.editedItem.phone,
        role: this.defaultRoles.includes(this.editedItem.role)
          ? this.editedItem.role
          : "user",
        type: "Professional Excavator",
        persona: this.editedItem.role,
      };
      if (this.editedItem.approver_id && this.isApprover)
        userObject.approver_id = this.editedItem.approver_id;
      const addUserStatus = await this.$store.dispatch(
        "inviteUser",
        userObject,
      );
      if (addUserStatus.status !== "error") {
        this.welcomeEmailSentObject.text.bodyLabel = `We sent an email to <strong>${this.editedItem.email}</strong> with a link to set up account.`;
        this.getUsers(this.companyId);
        this.isWelcomeEmailSent = true;
        this.welcomeEmailSentObject.text.bodyLabelParams.email =
          this.editedItem.email;
      } else if (addUserStatus.error === ERRORS.USER_IS_ALREADY_EXIST) {
        this.isUserExists = true;
      } else {
        this.$emit("error");
      }
      this.enableInvite = true;
    },
    close() {
      this.dialog = false;
    },
    onClickHeaderCheckbox() {
      if (this.selected.length < this.usersData.length) {
        this.selected = this.usersData;
      } else {
        this.selected = [];
      }
    },
    async resendInvite(item) {
      this.isUserExists = false;
      this.emailInUse = false;
      let userObject = {
        id: item.user_id,
        name: item.name,
        email: item.email,
        phone: item.phone,
        role: item.role,
        type: "Professional Excavator",
        persona: item.role,
        company_id: item.company_id,
        status: "pending",
      };
      if (item.approver_id && this.isApprover)
        userObject.approver_id = item.approver_id;

      const updateUserStatus = await this.$store.dispatch(
        "reInviteUser",
        userObject,
      );

      if (updateUserStatus.status !== "error") {
        this.welcomeEmailSentObject.text.bodyLabel = `We sent an email to <strong>${item.email}</strong> with a link to set up account.`;
        this.resendDialog = true;
        this.getUsers(this.companyId);
        this.isWelcomeEmailSent = true;
        this.welcomeEmailSentObject.text.bodyLabelParams.email = item.email;
      } else {
        this.$emit("error");
      }
    },
  },
  components: { ConfirmationMessage },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.select-view {
  .v-text-field--outlined.v-input--dense.v-text-field--outlined
    > .v-input__control
    > .v-input__slot {
    width: 100px;
    min-height: 15px;
    height: 32px;
  }
  .v-text-field--enclosed.v-input--dense:not(
      .v-text-field--solo
    ).v-text-field--outlined
    .v-input__append-inner {
    margin: 0px;
    margin-top: 4px;
    padding: 0px;
  }
  .v-select.v-input--dense .v-chip {
    margin: 0 0px 0 4px;
  }
}
.v-data-table--mobile > .v-data-table__wrapper > table > thead > tr > th {
  padding: 0 !important;
}
.v-select__slot {
  padding-left: 2px !important;
  padding-right: 2px !important;
}
.v-text-field--rounded > .v-input__control > .v-input__slot {
  padding: 0 0px;
}
</style>
