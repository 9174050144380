var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pa-0", attrs: { cols: "12" } },
            [
              _vm.showAddUserAlert
                ? _c(
                    "v-alert",
                    {
                      staticClass: "caption font-weight-regular py-3 px-5",
                      attrs: {
                        type: "warning",
                        color: "#AD4C0B",
                        icon: "mdi-alert-outline",
                        text: "",
                        height: "44px",
                        dismissible: "",
                      },
                    },
                    [
                      _vm._v(" " + _vm._s(_vm.$t("userLimitExceeded")) + " "),
                      _c(
                        "a",
                        {
                          staticClass: "primary--text",
                          on: {
                            click: function ($event) {
                              return _vm.$router.push("/subscription")
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("upgradePlan")))]
                      ),
                      _vm._v(" " + _vm._s(_vm.$t("toAddMoreUser")) + " "),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticClass: "text-h5 font-weight-medium",
              class: _vm.$vuetify.breakpoint.mdAndUp ? "pl-8" : "pl-5",
              attrs: { cols: "8", md: "7", lg: "8" },
            },
            [_vm._v(" " + _vm._s(_vm.$t("users")) + " ")]
          ),
          _c("v-spacer"),
          _c(
            "div",
            { staticClass: "mr-3" },
            [
              _vm.$vuetify.breakpoint.mdAndUp && _vm.checkPermission("addUser")
                ? _c(
                    "v-col",
                    { staticClass: "ml-8" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "text-none rounded-lg",
                          attrs: {
                            color: "primary",
                            disabled: _vm.showAddUserAlert,
                          },
                          on: { click: _vm.addUser },
                        },
                        [
                          _c("v-icon", [_vm._v("mdi-plus")]),
                          _vm._v(" " + _vm._s(_vm.$t("addUser"))),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "justify-end" },
        [
          _vm.showSuccessMsg
            ? _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-alert",
                    {
                      staticClass: "ma-0",
                      attrs: {
                        type: "success",
                        dismissible: "",
                        "max-height": "250",
                        dense: "",
                        text: "",
                      },
                    },
                    [_c("span", [_vm._v(" User updated successfully ! ")])]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("v-row", { staticClass: "mb-2" }, [_c("v-divider")], 1),
      _c(
        "v-card",
        {
          class: _vm.$vuetify.breakpoint.smAndDown ? "mb-12" : null,
          attrs: { elevation: "0", width: "100%" },
        },
        [
          _c(
            "v-card-title",
            { staticClass: "pr-0" },
            [
              _c(
                "v-row",
                [
                  _c(
                    "span",
                    {
                      staticClass: "text-subtitle-2 my-auto",
                      class: _vm.$vuetify.breakpoint.mdAndUp ? "ml-4" : "ml-1",
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("totalUsers")) +
                          " : " +
                          _vm._s(_vm.usersData.length)
                      ),
                    ]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-col",
                    {
                      class: !_vm.$vuetify.breakpoint.mdAndUp
                        ? "pl-0 pr-4"
                        : "pr-6",
                      attrs: { cols: "12", lg: "4", md: "4", xl: "4" },
                    },
                    [
                      _c("v-text-field", {
                        staticClass: "rounded-lg",
                        attrs: {
                          "prepend-inner-icon": "mdi-magnify",
                          label: _vm.$t("search"),
                          "single-line": "",
                          "hide-details": "",
                          flat: "",
                          solo: "",
                          "background-color": "grey lighten-4",
                        },
                        model: {
                          value: _vm.search,
                          callback: function ($$v) {
                            _vm.search = $$v
                          },
                          expression: "search",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-data-table", {
            staticClass: "elevation-0 pa-0",
            class: _vm.$vuetify.breakpoint.mdAndUp ? "ml-2" : null,
            attrs: {
              headers: _vm.headers,
              items: _vm.usersData,
              "items-per-page": !_vm.$vuetify.breakpoint.mdAndUp ? -1 : 15,
              search: _vm.search,
              "item-key": "email",
              color: "primary",
              "checkbox-color": "primary",
              "hide-default-footer": !_vm.$vuetify.breakpoint.mdAndUp,
              loading: _vm.usersData.length == 0,
              "loading-text": "Loading... Please wait",
              "mobile-breakpoint": "950",
              "disable-sort": "",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "top",
                  fn: function () {
                    return [
                      _c(
                        "v-dialog",
                        {
                          attrs: {
                            persistent: "",
                            fullscreen: _vm.$vuetify.breakpoint.smAndDown,
                            "max-width": "500px",
                          },
                          model: {
                            value: _vm.dialog,
                            callback: function ($$v) {
                              _vm.dialog = $$v
                            },
                            expression: "dialog",
                          },
                        },
                        [
                          !_vm.isWelcomeEmailSent
                            ? _c(
                                "v-card",
                                [
                                  _c(
                                    "v-card-title",
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-h6 font-weight-medium",
                                        },
                                        [_vm._v(_vm._s(_vm.formTitle))]
                                      ),
                                      _c("v-spacer"),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            icon: "",
                                            "aria-label": "close",
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.dialog = false
                                            },
                                          },
                                        },
                                        [_c("v-icon", [_vm._v("mdi-close")])],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-card-text",
                                    [
                                      _c(
                                        "v-container",
                                        { staticClass: "px-0" },
                                        [
                                          _c(
                                            "v-row",
                                            {
                                              attrs: {
                                                "no-gutters": "",
                                                justify: "center",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c(
                                                    "v-alert",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            _vm.isUserExists,
                                                          expression:
                                                            "isUserExists",
                                                        },
                                                      ],
                                                      attrs: {
                                                        color: "red",
                                                        dismissible: "",
                                                        text: "",
                                                        icon: "mdi-alert-circle-outline",
                                                        dense: "",
                                                        type: "error",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "my-auto font-weight-regular caption",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "userInviteError"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-alert",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value: _vm.emailInUse,
                                                          expression:
                                                            "emailInUse",
                                                        },
                                                      ],
                                                      attrs: {
                                                        color: "red",
                                                        dismissible: "",
                                                        text: "",
                                                        icon: "mdi-alert-circle-outline",
                                                        dense: "",
                                                        type: "error",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "my-auto font-weight-regular caption",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "emailAllreadyRegistered"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            {
                                              attrs: {
                                                "no-gutters": "",
                                                justify: "center",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _vm.editedItem.status ===
                                                    "Active" ||
                                                  _vm.editedItem.status ===
                                                    "Inactive"
                                                    ? _c(
                                                        "v-row",
                                                        {
                                                          attrs: {
                                                            "no-gutters": "",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "select-view",
                                                              attrs: {
                                                                cols:
                                                                  _vm.$vuetify
                                                                    .breakpoint
                                                                    .width <=
                                                                  365
                                                                    ? 6
                                                                    : 4,
                                                                lg: "3",
                                                                md: "3",
                                                                sm: "3",
                                                              },
                                                            },
                                                            [
                                                              _c("v-select", {
                                                                attrs: {
                                                                  disabled:
                                                                    !_vm.allowChangeStatus,
                                                                  items:
                                                                    _vm.statusOptions,
                                                                  "menu-props":
                                                                    {
                                                                      bottom: true,
                                                                      offsetY: true,
                                                                    },
                                                                  "small-chips":
                                                                    "",
                                                                  outlined: "",
                                                                  dense: "",
                                                                  rounded: "",
                                                                  required: "",
                                                                  label: "",
                                                                  "aria-label":
                                                                    _vm
                                                                      .editedItem
                                                                      .status,
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "selection",
                                                                        fn: function (
                                                                          ref
                                                                        ) {
                                                                          var item =
                                                                            ref.item
                                                                          return [
                                                                            _c(
                                                                              "v-chip",
                                                                              {
                                                                                staticClass:
                                                                                  "white--text",
                                                                                attrs:
                                                                                  {
                                                                                    small:
                                                                                      "",
                                                                                    color:
                                                                                      _vm.getColor(
                                                                                        item
                                                                                      ),
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      item
                                                                                    )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    false,
                                                                    3204592370
                                                                  ),
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .editedItem
                                                                      .status,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.editedItem,
                                                                        "status",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "editedItem.status",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                          !_vm.allowChangeStatus
                                                            ? _c(
                                                                "v-tooltip",
                                                                {
                                                                  staticClass:
                                                                    "pa-0",
                                                                  attrs: {
                                                                    right:
                                                                      !_vm
                                                                        .$vuetify
                                                                        .breakpoint
                                                                        .smAndDown,
                                                                    bottom:
                                                                      _vm
                                                                        .$vuetify
                                                                        .breakpoint
                                                                        .smAndDown,
                                                                    "max-width":
                                                                      "400",
                                                                  },
                                                                  scopedSlots:
                                                                    _vm._u(
                                                                      [
                                                                        {
                                                                          key: "activator",
                                                                          fn: function (
                                                                            ref
                                                                          ) {
                                                                            var on =
                                                                              ref.on
                                                                            var attrs =
                                                                              ref.attrs
                                                                            return [
                                                                              _c(
                                                                                "v-icon",
                                                                                _vm._g(
                                                                                  _vm._b(
                                                                                    {
                                                                                      staticClass:
                                                                                        "mb-6",
                                                                                      attrs:
                                                                                        {
                                                                                          color:
                                                                                            "primary",
                                                                                          dark: "",
                                                                                        },
                                                                                    },
                                                                                    "v-icon",
                                                                                    attrs,
                                                                                    false
                                                                                  ),
                                                                                  on
                                                                                ),
                                                                                [
                                                                                  _vm._v(
                                                                                    " mdi-information-outline "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          },
                                                                        },
                                                                      ],
                                                                      null,
                                                                      false,
                                                                      3010457594
                                                                    ),
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "This user has been deactivated. Please contact our support team at"
                                                                    ),
                                                                    _c("a", [
                                                                      _vm._v(
                                                                        " support@norfielddp.com"
                                                                      ),
                                                                    ]),
                                                                  ]),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      "error-messages":
                                                        _vm.nameErrors,
                                                      label: _vm.$t("fullName"),
                                                      outlined: "",
                                                      required: "",
                                                      dense: "",
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.$v.editedItem.name.$touch()
                                                      },
                                                      blur: function ($event) {
                                                        return _vm.$v.editedItem.name.$touch()
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.editedItem.name,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.editedItem,
                                                          "name",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "editedItem.name",
                                                    },
                                                  }),
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      "error-messages":
                                                        _vm.emailErrors,
                                                      label:
                                                        _vm.$t("emailAddress"),
                                                      outlined: "",
                                                      required: "",
                                                      dense: "",
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.$v.editedItem.email.$touch()
                                                      },
                                                      blur: function ($event) {
                                                        return _vm.$v.editedItem.email.$touch()
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.editedItem.email,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.editedItem,
                                                          "email",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "editedItem.email",
                                                    },
                                                  }),
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      "error-messages":
                                                        _vm.phoneErrors,
                                                      label:
                                                        _vm.$t("phoneNumber"),
                                                      outlined: "",
                                                      required: "",
                                                      dense: "",
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.$v.editedItem.phone.$touch()
                                                      },
                                                      blur: function ($event) {
                                                        return _vm.$v.editedItem.phone.$touch()
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.editedItem.phone,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.editedItem,
                                                          "phone",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "editedItem.phone",
                                                    },
                                                  }),
                                                  _c("v-select", {
                                                    attrs: {
                                                      items:
                                                        _vm.userRoles[
                                                          _vm.companyUserRole
                                                        ],
                                                      "error-messages":
                                                        _vm.roleErrors,
                                                      "item-value": "value",
                                                      "item-text": "text",
                                                      "menu-props": {
                                                        bottom: true,
                                                        offsetY: true,
                                                      },
                                                      disabled:
                                                        _vm.editedIndex !==
                                                          -1 &&
                                                        _vm.companyUserRole !=
                                                          "admin",
                                                      outlined: "",
                                                      required: "",
                                                      dense: "",
                                                      label: _vm.$t("role"),
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.$v.editedItem.role.$touch()
                                                      },
                                                      blur: function ($event) {
                                                        return _vm.$v.editedItem.role.$touch()
                                                      },
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "item",
                                                          fn: function (ref) {
                                                            var item = ref.item
                                                            var on = ref.on
                                                            var attrs =
                                                              ref.attrs
                                                            return [
                                                              _c(
                                                                "v-list-item",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {},
                                                                    "v-list-item",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _c(
                                                                    "v-list-item-content",
                                                                    [
                                                                      _c(
                                                                        "v-list-item-title",
                                                                        {
                                                                          staticClass:
                                                                            "text-left",
                                                                          domProps:
                                                                            {
                                                                              textContent:
                                                                                _vm._s(
                                                                                  item.text
                                                                                ),
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      1858403849
                                                    ),
                                                    model: {
                                                      value:
                                                        _vm.editedItem.role,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.editedItem,
                                                          "role",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "editedItem.role",
                                                    },
                                                  }),
                                                  _vm.approverCheck
                                                    ? _c(
                                                        "v-row",
                                                        {
                                                          staticClass: "px-4",
                                                          class: !_vm.isApprover
                                                            ? "pb-5"
                                                            : "pb-2",
                                                        },
                                                        [
                                                          _c("v-switch", {
                                                            staticClass:
                                                              "info--text switch-track-inset-opacity switch-track-inset-height mt-3 mr-2",
                                                            attrs: {
                                                              dense: "",
                                                              inset: "",
                                                              color: "info",
                                                              label:
                                                                "Approval Required",
                                                              "background-color":
                                                                "white",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.isApprover,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.isApprover =
                                                                    $$v
                                                                },
                                                              expression:
                                                                "isApprover",
                                                            },
                                                          }),
                                                          _c(
                                                            "v-tooltip",
                                                            {
                                                              staticClass:
                                                                "pa-0",
                                                              attrs: {
                                                                right:
                                                                  !_vm.$vuetify
                                                                    .breakpoint
                                                                    .smAndDown,
                                                                bottom:
                                                                  _vm.$vuetify
                                                                    .breakpoint
                                                                    .smAndDown,
                                                                "max-width":
                                                                  "400",
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "activator",
                                                                      fn: function (
                                                                        ref
                                                                      ) {
                                                                        var on =
                                                                          ref.on
                                                                        var attrs =
                                                                          ref.attrs
                                                                        return [
                                                                          _c(
                                                                            "v-icon",
                                                                            _vm._g(
                                                                              _vm._b(
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      color:
                                                                                        "primary",
                                                                                      dark: "",
                                                                                    },
                                                                                },
                                                                                "v-icon",
                                                                                attrs,
                                                                                false
                                                                              ),
                                                                              on
                                                                            ),
                                                                            [
                                                                              _vm._v(
                                                                                " mdi-information-outline "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  false,
                                                                  2517027790
                                                                ),
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "theAssignedApproveText"
                                                                    )
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm.isApprover &&
                                                  _vm.approverCheck
                                                    ? _c("v-select", {
                                                        attrs: {
                                                          items:
                                                            _vm.approversList,
                                                          "error-messages":
                                                            _vm.roleErrors,
                                                          "item-value":
                                                            "user_id",
                                                          "item-text": "name",
                                                          "menu-props": {
                                                            bottom: true,
                                                            offsetY: true,
                                                          },
                                                          disabled:
                                                            _vm.editedIndex !==
                                                              -1 &&
                                                            _vm.companyUserRole !=
                                                              "admin",
                                                          outlined: "",
                                                          required: "",
                                                          dense: "",
                                                          label:
                                                            _vm.$t(
                                                              "approverName"
                                                            ),
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.$v.editedItem.role.$touch()
                                                          },
                                                          blur: function (
                                                            $event
                                                          ) {
                                                            return _vm.$v.editedItem.role.$touch()
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.editedItem
                                                              .approver_id,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.editedItem,
                                                              "approver_id",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "editedItem.approver_id",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-card-actions",
                                    { staticClass: "mt-4" },
                                    [
                                      _c(
                                        "v-footer",
                                        {
                                          attrs: {
                                            absolute: "",
                                            color: "white",
                                            elevation: _vm.$vuetify.breakpoint
                                              .smAndDown
                                              ? 3
                                              : 1,
                                          },
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            {
                                              staticClass: "px-2 mb-3",
                                              class: {
                                                "mr-4 d-flex justify-end":
                                                  _vm.$vuetify.breakpoint
                                                    .mdAndUp,
                                              },
                                              attrs: { "no-gutters": "" },
                                            },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "pr-3",
                                                  class: {
                                                    "mx-2":
                                                      _vm.$vuetify.breakpoint
                                                        .mdAndUp,
                                                  },
                                                  attrs: {
                                                    cols: "6",
                                                    lg: "2",
                                                    md: "2",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "text-none rounded-lg",
                                                      attrs: {
                                                        block: "",
                                                        outlined: "",
                                                        color: "primary",
                                                      },
                                                      on: { click: _vm.close },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t("cancel")
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "pl-3",
                                                  class: {
                                                    "mx-2":
                                                      _vm.$vuetify.breakpoint
                                                        .mdAndUp,
                                                  },
                                                  attrs: {
                                                    cols: "6",
                                                    lg:
                                                      _vm.editedIndex !== -1
                                                        ? 2
                                                        : 3,
                                                    md:
                                                      _vm.editedIndex !== -1
                                                        ? 2
                                                        : 3,
                                                  },
                                                },
                                                [
                                                  _vm.editedIndex !== -1
                                                    ? _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "text-none rounded-lg",
                                                          attrs: {
                                                            block: "",
                                                            color: "primary",
                                                            disabled:
                                                              _vm.$v.$invalid ||
                                                              _vm.isApproverCheck,
                                                          },
                                                          on: {
                                                            click: _vm.update,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t("update")
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm.editedIndex === -1
                                                    ? _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "text-none rounded-lg",
                                                          attrs: {
                                                            block: "",
                                                            color: "primary",
                                                            disabled:
                                                              _vm.$v.$invalid ||
                                                              _vm.isApproverCheck ||
                                                              !_vm.enableInvite,
                                                          },
                                                          on: {
                                                            click: _vm.submit,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "inviteUser"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _c(
                                "v-card",
                                { staticClass: "pa-4" },
                                [
                                  _c("ConfirmationMessage", {
                                    attrs: {
                                      icon: _vm.welcomeEmailSentObject.icon,
                                      text: _vm.welcomeEmailSentObject.text,
                                      button: _vm.welcomeEmailSentObject.button,
                                      "show-logo":
                                        _vm.welcomeEmailSentObject.showLogo,
                                      "background-color":
                                        _vm.welcomeEmailSentObject
                                          .backgroundColor,
                                    },
                                    on: { onClickDone: _vm.onClickDone },
                                  }),
                                ],
                                1
                              ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "item.name",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-row",
                        {
                          staticClass: "text-subtitle-2 font-weight-bold ma-0",
                        },
                        [_vm._v(" " + _vm._s(item.name))]
                      ),
                      _c("v-row", { staticClass: "ma-0" }, [
                        _vm._v(" " + _vm._s(item.email)),
                      ]),
                    ]
                  },
                },
                {
                  key: "item.persona",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _vm._v(" " + _vm._s(_vm.getRoles(item.persona)) + " "),
                    ]
                  },
                },
                {
                  key: "item.approver_id",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      item.approver_id
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.approverRoleUsersMap[item.approver_id]
                                  ? _vm.approverRoleUsersMap[item.approver_id]
                                      .name
                                  : ""
                              )
                            ),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
                {
                  key: "item.created_at",
                  fn: function (ref) {
                    var item = ref.item
                    return [_vm._v(" " + _vm._s(item.created_at) + " ")]
                  },
                },
                {
                  key: "item.updated_at",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      item.updated_at === "Invalid date"
                        ? _c("v-row", { attrs: { justify: "center" } }, [
                            _vm._v(" — "),
                          ])
                        : _c("v-row", [
                            _vm._v(" " + _vm._s(item.updated_at) + " "),
                          ]),
                    ]
                  },
                },
                {
                  key: "item.edit",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _vm.checkPermission("editUser")
                        ? _c(
                            "v-icon",
                            {
                              staticClass: "mr-2",
                              attrs: {
                                disabled: _vm.companyUserId === item.user_id,
                                "aria-label": "Edit",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.editItem(item)
                                },
                              },
                            },
                            [_vm._v(" mdi-pencil-outline ")]
                          )
                        : _vm._e(),
                    ]
                  },
                },
                {
                  key: "item.status",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-chip",
                        {
                          staticClass: "white--text",
                          attrs: {
                            color: _vm.getColor(item.status),
                            small: "",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t(item.status)) + " ")]
                      ),
                      item.status === "expired" || item.status === "pending"
                        ? _c(
                            "v-btn",
                            {
                              staticClass:
                                "text-none rounded-lg white--text ml-2",
                              attrs: { color: "primary", height: "20" },
                              on: {
                                click: function ($event) {
                                  return _vm.resendInvite(item)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("resend")) + " ")]
                          )
                        : _vm._e(),
                    ]
                  },
                },
                !_vm.$vuetify.breakpoint.mdAndUp
                  ? {
                      key: "body",
                      fn: function (ref) {
                        var items = ref.items
                        return [
                          _c(
                            "tbody",
                            _vm._l(items, function (item) {
                              return _c(
                                "tr",
                                { key: item.email, staticClass: "pb-4" },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      attrs: { height: "210", elevation: "0" },
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "ml-1",
                                              attrs: { cols: "7" },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "text-subtitle-2 font-weight-bold",
                                                },
                                                [
                                                  _vm._v(
                                                    " " + _vm._s(item.name)
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c("v-spacer"),
                                          _c(
                                            "v-col",
                                            { staticClass: "pr-0" },
                                            [
                                              _c(
                                                "v-chip",
                                                {
                                                  staticClass:
                                                    "white--text mr-4",
                                                  attrs: {
                                                    "x-small": "",
                                                    color: _vm.getColor(
                                                      item.status
                                                    ),
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(item.status)
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              item.status === "expired" ||
                                              item.status === "pending"
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "text-none rounded-lg white--text",
                                                      attrs: {
                                                        color: "primary",
                                                        height: "20",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.resendInvite(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t("resend")
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { staticClass: "ml-1" },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0",
                                              attrs: { cols: "4" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("role")) + ":"
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "pa-0 font-weight-medium",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getRoles(item.persona)
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { staticClass: "ml-1" },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0",
                                              attrs: { cols: "4" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("emailId")) + ":"
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "pa-0 font-weight-medium",
                                            },
                                            [_vm._v(" " + _vm._s(item.email))]
                                          ),
                                        ],
                                        1
                                      ),
                                      item.approver_id
                                        ? _c(
                                            "v-row",
                                            { staticClass: "ml-1" },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "pa-0",
                                                  attrs: { cols: "4" },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("approver")) +
                                                      ":"
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "pa-0 font-weight-medium",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm
                                                          .approverRoleUsersMap[
                                                          item.approver_id
                                                        ]
                                                          ? _vm
                                                              .approverRoleUsersMap[
                                                              item.approver_id
                                                            ].name
                                                          : ""
                                                      )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-row",
                                        { staticClass: "ml-1" },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0",
                                              attrs: { cols: "4" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("created")) + ":"
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "pa-0 font-weight-medium",
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.created_at)
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { staticClass: "ml-1" },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0",
                                              attrs: { cols: "4" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("updated")) + ":"
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "pa-0 font-weight-medium",
                                            },
                                            [
                                              item.updated_at === "Invalid date"
                                                ? _c(
                                                    "span",
                                                    {
                                                      attrs: {
                                                        justify: "center",
                                                      },
                                                    },
                                                    [_vm._v(" — ")]
                                                  )
                                                : _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.updated_at
                                                        ) +
                                                        " "
                                                    ),
                                                  ]),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.checkPermission("editUser")
                                        ? _c(
                                            "v-row",
                                            { staticClass: "ml-1 pt-4 mb-4" },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "pa-0",
                                                  attrs: { cols: "3" },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "mr-3",
                                                      attrs: {
                                                        disabled:
                                                          _vm.companyUserId ===
                                                          item.user_id,
                                                        "aria-label": "Edit",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.editItem(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " mdi-pencil-outline "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c("v-divider"),
                                ],
                                1
                              )
                            }),
                            0
                          ),
                        ]
                      },
                    }
                  : null,
              ],
              null,
              true
            ),
            model: {
              value: _vm.selected,
              callback: function ($$v) {
                _vm.selected = $$v
              },
              expression: "selected",
            },
          }),
        ],
        1
      ),
      _vm.$vuetify.breakpoint.smAndDown && _vm.checkPermission("addUser")
        ? _c(
            "v-bottom-navigation",
            {
              staticClass: "px-2 py-4",
              attrs: { fixed: "", horizontal: "", height: "70" },
              model: {
                value: _vm.bottomNavbar,
                callback: function ($$v) {
                  _vm.bottomNavbar = $$v
                },
                expression: "bottomNavbar",
              },
            },
            [
              _c(
                "v-row",
                { staticClass: "px-4 py-2" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "text-none rounded-lg text-h6 white--text",
                      attrs: { color: "primary", block: "", height: "50" },
                      on: { click: _vm.addUser },
                    },
                    [
                      _vm._v(_vm._s(_vm.$t("addUser"))),
                      _c("v-icon", [_vm._v("mdi-plus")]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: {
            persistent: "",
            fullscreen: _vm.$vuetify.breakpoint.smAndDown,
            "max-width": "500px",
          },
          model: {
            value: _vm.resendDialog,
            callback: function ($$v) {
              _vm.resendDialog = $$v
            },
            expression: "resendDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("ConfirmationMessage", {
                attrs: {
                  icon: _vm.welcomeEmailSentObject.icon,
                  text: _vm.welcomeEmailSentObject.text,
                  button: _vm.welcomeEmailSentObject.button,
                  "show-logo": _vm.welcomeEmailSentObject.showLogo,
                  "background-color":
                    _vm.welcomeEmailSentObject.backgroundColor,
                },
                on: { onClickDone: _vm.onClickDone },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }